<template>
  <div class="the-tab">
    <div
      v-for="tab in tabs"
      :key="tab.text"
      class="tab"
      :class="{ 'tab--active': tab === value }"
      @click="$emit('input', tab)"
    >
      {{ tab }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    value: {
      default: ""
    }
  }
};
</script>

<style scoped>
.the-tab {
  display: inline-flex;
  align-items: center;
  background: #f5f6fe;
  border-radius: 400px;
  padding: 2px 3px;
}

.tab {
  padding: 10px 16px;
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  border: 1px solid #f5f6fe;
  cursor: pointer;
}

.tab--active {
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 100px;
}

@media screen and (max-width: 720px) {
  .tab {
    padding: 10px 12px;
  }
}
</style>
